#root {
	height: 100%;
	min-height: 100vh;
}

body {
	margin: 0px;
	font-family: 'Raleway', -apple-system, BlinkMacSystemFont, 'Segoe UI',
		'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
		'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background: #452c63;
}

div {
	color: white;
	font-weight: 600;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
}

hr {
	border-color: #b19cd9;
}

.container {
	display: flex;
	/* min-height: 100vh; */
	/* height: 100%; */
	width: 100%;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}

.search-box {
	text-align: center;
	font-family: "Raleway", "Helvetica Neue", sans-serif;
	font-weight: bold;
	height: 40px;
	width: 250px;
	margin: 20px;
	border-width: 2px;
	border-radius: 35px;
}

.btn-grad {
	/* Created with https://www.css-gradient.com */
	background: #D6A4FC;
	background: -webkit-linear-gradient(top left, #D6A4FC, #853CAF);
	background: -moz-linear-gradient(top left, #D6A4FC, #853CAF);
	background: linear-gradient(to bottom right, #D6A4FC, #853CAF);
	margin: 10px;
	padding: 15px 45px;
	text-align: center;
	text-transform: uppercase;
	transition: 0.5s;
	background-size: 200% auto;
	color: white;
	box-shadow: 0 20px 20px 2px rgb(0, 0, 0, 0.1);
	border-radius: 35px;
	display: block;
	cursor: pointer;
}

.btn-grad:hover {
	background-position: right center;
	/* change the direction of the change here */
	color: #fff;
	/* text-decoration: none; */
}

.meaning-box {
	margin: 10px;
	font-size: medium;
	background-color: rgb(0, 0, 0, 0.3);
	height: auto;
	border-radius: 25px;
	padding: 25px;
	min-width: 280px;
	box-shadow: 0 20px 50px 2px rgb(0, 0, 0, 0.2);
}

.definition-box {
	padding: 5px;
}

.main-box {
	font-size: xx-large;
	color: #b19cd9;
	gap: 5px;
}

.btn-cursor {
	cursor: pointer;
}

.btn-cursor:hover {
	background-color: rgb(50, 50, 50, 0.15);
}